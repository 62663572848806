import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.section`
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 24px;

    ${media.lgUp} {
        padding-bottom: 48px;
    }
`;

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    ${media.mdUp} {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    ${media.lgUp} {
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
`;

export const ListItem = styled.li`
    ${media.xlUp} {
        :nth-of-type(even) {
            margin-top: 112px;
        }
    }
`;

export const SecondaryTitle = styled.div`
    color: var(--color-orange-50);
    font-family: var(--font-tertiary);
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    transform: rotate(-4deg);

    ${media.lgUp} {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: -5px;
    }
`;

export const Title = styled.h2`
    color: var(--color-brown-50);
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;

    ${media.lgUp} {
        font-size: 56px;
        line-height: 64px;
    }
`;

export const Top = styled.div`
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
`;

export const CarouselWrapper = styled.div`
    padding: 0 0 0 24px;
`;

export const SliderWrapper = styled.div`
    padding: 0 24px;
`;
