import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

import { media } from "constants/media";

const pulseAnimation = keyframes`
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1200px;

    ${media.mdUp} {
        display: flex;
        flex-direction: row-reverse;
        padding: 50px 0 0;
        position: relative;
        z-index: 10;
    }
`;

export const Title = styled.div`
    color: var(--color-brown-50);
    font-family: var(--font-secondary);
    margin-bottom: 34px;
    max-width: 325px;

    h1 {
        font-size: 38px;
        font-weight: 400;
        line-height: 40px;
    }

    ${media.mdUp} {
        margin: 0 0 32px;
    }

    ${media.lgUp} {
        h1 {
            font-size: 98px;
            line-height: 96px;

            text-align: left;
        }
    }
`;

export const Description = styled(motion.div)`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const ButtonContainer = styled(motion.div)`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
        margin-bottom: 16px;
    }

    ${media.mdUp} {
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const HeroContent = styled(motion.div)`
    flex: 1;
    padding: 20px;

    ${media.mdUp} {
        flex: 1 1 100%;
    }
`;

export const BackgroundContainer = styled(motion.div)`
    flex: 1 1 100%;
    height: 268px;
    margin-bottom: -80px;
    max-width: 100%;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        height: 400px;
        margin-bottom: 0;
        width: 100%;
    }

    ${media.lgUp} {
        height: 600px;
        max-width: 50%;
    }
`;

export const BackroundWrapper = styled.div`
    height: 268px;
    left: 0;
    margin: 0 auto;
    max-width: 268px;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        flex: 1 1 775px;
        max-width: 775px;
    }

    ${media.lgUp} {
        width: 775px;
    }
`;

export const StyledTok1 = styled(motion.span)`
    animation: ${pulseAnimation} 4s infinite linear;
    animation-delay: 3s;
    height: 41.94px;
    left: -7%;
    opacity: 0;
    position: absolute;
    top: 23%;
    width: 47.36px;

    ${media.lgUp} {
        height: 48.44px;
        width: 54.7px;
    }
`;

export const StyledTok2 = styled.span`
    animation: ${pulseAnimation} 4s infinite linear;
    animation-delay: 1.5s;
    height: 32.92px;
    left: -15%;
    opacity: 0;
    position: absolute;
    top: 35%;
    width: 37.18px;

    ${media.lgUp} {
        height: 70px;
        width: 98px;
    }
`;
