import { Richtext } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import Tok1 from "public/images/tok-1.png";
import Tok2 from "public/images/tok-2.png";

import { ARROW_RIGHT } from "components/shared/icon";
import {
    BackgroundContainer,
    BackroundWrapper,
    ButtonContainer,
    Description,
    HeroContent,
    StyledTok1,
    StyledTok2,
    Title,
    Wrapper
} from "components/homePage/hero/Hero.styled";
import { Button } from "components/shared/button/Button";
import { Content } from "components/shared/content/Content";
import TrustBox from "components/preHeader/trustBox/TrustBox";

import { useMediaQueryContext } from "helpers/hooks";

type LinkProps = {
    url: string;
    cached_url: string;
};

type Props = {
    title: Richtext;
    introText: string;
    button: LinkProps;
    buttonText: string;
    textButton: LinkProps;
    textButtonText: string;
};
export function Hero({
    title,
    introText,
    button,
    buttonText,
    textButton,
    textButtonText
}: Props): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();

    const content = {
        in: {
            opacity: 1,
            transition: {
                when: "beforeChildren"
            },
            y: 0
        },
        out: {
            opacity: 0,
            transition: { staggerChildren: 0.5, when: "afterChildren" },
            y: -50
        }
    };

    const contentItem = {
        in: (i: number) => ({
            opacity: 1,
            transition: { delay: i * 0.2 },
            y: 0
        }),
        out: {
            opacity: 0,
            y: -50
        }
    };

    return (
        <section>
            <TrustBox view="mobile" />
            <Wrapper>
                <BackgroundContainer>
                    <BackroundWrapper>
                        <StyledTok1>
                            <Image
                                src={Tok1}
                                alt="tok1"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </StyledTok1>
                        <StyledTok2>
                            <Image
                                src={Tok2}
                                alt="tok2"
                                layout="fill"
                                priority
                                objectFit="contain"
                            />
                        </StyledTok2>
                        <Image
                            src={
                                !isDesktopView
                                    ? "/images/chicken-mobile.webp"
                                    : "/images/chicken-desktop.webp"
                            }
                            alt="hero image"
                            layout="intrinsic"
                            width={!isDesktopView ? 265 : 775}
                            height={!isDesktopView ? 265 : 775}
                            objectFit="cover"
                            objectPosition="left top"
                            draggable={false}
                            priority
                            unoptimized
                        />
                    </BackroundWrapper>
                </BackgroundContainer>
                <HeroContent
                    initial={isDesktopView ? "out" : "in"}
                    animate="in"
                    variants={content}
                >
                    <Title>
                        <Content content={title} />
                    </Title>
                    <Description custom={1} variants={contentItem}>
                        <Content content={introText} />
                    </Description>
                    <ButtonContainer custom={2} variants={contentItem}>
                        <Link
                            href={`/${
                                button.url.length > 0
                                    ? button.url
                                    : button.cached_url
                            }`}
                            passHref
                            prefetch={false}
                        >
                            <Button size="large" buttonType="secondary">
                                {buttonText}
                            </Button>
                        </Link>
                        <Link
                            href={`/${
                                button.url.length > 0
                                    ? textButton.url
                                    : textButton.cached_url
                            }`}
                            passHref
                            prefetch={false}
                        >
                            <Button
                                variant="blue"
                                size="link"
                                buttonType="link"
                                icon={{ icon: ARROW_RIGHT }}
                                reversedOrder
                                $showUnderlineOnMobile
                            >
                                {textButtonText}
                            </Button>
                        </Link>
                    </ButtonContainer>
                </HeroContent>
            </Wrapper>
        </section>
    );
}
