import { ReactNode } from "react";

import { StyledUnorderedList } from "./List.styled";

export interface Props {
    children?: ReactNode;
}

export function UnorderedList({ children }: Props): JSX.Element {
    return <StyledUnorderedList>{children}</StyledUnorderedList>;
}
