import {
    NODE_HEADING,
    NODE_IMAGE,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    render
} from "storyblok-rich-text-react-renderer";
import Image, { ImageProps } from "next/image";
import styled, { css } from "styled-components";

import { OrderedList } from "../list/orderedList";
import { UnorderedList } from "../list/unorderedList";

import { media } from "constants/media";

const StyledHeading = styled.h1<{ darkMode: boolean }>`
    color: var(--color-primary-dark);
    margin-bottom: 8px;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ darkMode }) =>
        darkMode &&
        css`
            color: var(--color-white);
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

const StyledParagraph = styled.p<{ darkMode: boolean }>`
    margin-bottom: 16px;

    > a {
        color: var(--color-blue-50);
        display: contents;
        position: relative;
    }

    ${media.mdUp} {
        margin-bottom: 24px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ darkMode }) =>
        darkMode &&
        css`
            color: var(--color-white);
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

// eslint-disable-next-line
export function Content({ content, darkMode }: any): JSX.Element | null {
    return render(content, {
        nodeResolvers: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [NODE_HEADING]: (children, { level }) => (
                <StyledHeading
                    darkMode={darkMode}
                    as={`h${level}` as keyof unknown}
                >
                    {children}
                </StyledHeading>
            ),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [NODE_IMAGE]: (_children, { src, alt, title }: ImageProps) => (
                <>
                    <Image
                        layout="responsive"
                        height={200}
                        width={400}
                        src={src}
                        alt={alt}
                        title={title}
                        unoptimized
                    />
                </>
            ),
            [NODE_OL]: children => <OrderedList>{children}</OrderedList>,
            [NODE_PARAGRAPH]: children => (
                <StyledParagraph darkMode={darkMode}>
                    {children}
                </StyledParagraph>
            ),
            [NODE_UL]: children => <UnorderedList>{children}</UnorderedList>
        }
    });
}
