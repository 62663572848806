import SbEditable from "storyblok-react";

import components from "constants/components";

import { GenericStoryBlok } from "types/storyblok";

type Props = {
    blok: GenericStoryBlok;
};

type ComponentTypes = "recipeCard" | "page" | "storesList";

const DynamicComponent = ({ blok }: Props): JSX.Element => {
    const index = blok.component as ComponentTypes;
    const Component = components[index];

    if (blok && Component) {
        return (
            <SbEditable content={blok}>
                <Component {...blok} blok={blok} />
            </SbEditable>
        );
    }

    // fallback if the component doesn't exist
    if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test"
    ) {
        return (
            <p>
                The component <strong>{index}</strong> has not been created yet.
            </p>
        );
    }

    return <></>;
};

export default DynamicComponent;
