import Image from "next/image";

import { ARROW_RIGHT, Button } from "components";
import {
    ButtonContainer,
    Container,
    IconWrapper,
    ImageContainer,
    ImageWrapper,
    Info,
    MoreInfoContainer,
    Name,
    Price,
    PriceButton,
    StyledButton
} from "components/shared/productItem/ProductItem.styled";
import { Icon, PLUS } from "components/shared/icon";
import { Link as StyledLink } from "components/shared/link/Link";
import ClipSvg from "components/shared/clipSvg/ClipSvg";

import { formatPrice } from "helpers/format";
import { getProductTypeImage } from "helpers/util/product";
import { prodcutClickDataLayer } from "lib/gtag/events/productClick";
import { selectOrDeselectItem } from "lib/gtag";
import { useMediaQueryContext } from "helpers/hooks";
import { usePackageCartContext } from "helpers/context/PackageCartContext";

export enum SIZE {
    SMALL = "small",
    LARGE = "large"
}

type Props = {
    item: MagentoProduct;
    size?: SIZE.LARGE | SIZE.SMALL;
    pushToDataLayer?: boolean;
    positionInList?: number;
    list?: string;
    category?: string;
};

export default function Product({
    item,
    size = SIZE.LARGE,

    pushToDataLayer = false,
    positionInList = 0,
    list = "",
    category = ""
}: Props): JSX.Element {
    const { addProduct, canAddProduct } = usePackageCartContext();
    const { isMobileView } = useMediaQueryContext();
    const priceValue = item?.price_per_kilogram;
    const finalPrice = formatPrice(priceValue);
    const isSmallCard = size === SIZE.SMALL;

    const iconSize = isMobileView ? 66 : 84;

    function selectItemInDataLayer() {
        if (item && window) {
            const productData = {
                brand: "Mr. Fillet",
                category: "Product",
                id: item.sku,
                name: item.name,
                price: item.price_range?.maximum_price.final_price.value,
                quantity: 1,
                variant: ""
            };
            selectOrDeselectItem("select", productData);
        }
    }

    return (
        <Container size={size} initial="rest" whileHover="hover" animate="rest">
            <StyledLink href={`/kip-bestellen/${item?.url_key}`} noUnderline>
                <ImageContainer
                    size={size}
                    onClick={() =>
                        pushToDataLayer
                            ? prodcutClickDataLayer(
                                  item,
                                  positionInList,
                                  list,
                                  category
                              )
                            : null
                    }
                >
                    <IconWrapper>
                        {getProductTypeImage(
                            item.categories?.[0]?.url_key,
                            iconSize,
                            iconSize
                        )}
                    </IconWrapper>
                    <ImageWrapper size={size}>
                        {item?.image?.url && (
                            <Image
                                style={{ cursor: "pointer" }}
                                src={item.image.url}
                                layout="fill"
                                alt={item?.image?.label ?? ""}
                                className="clipped-image"
                                objectFit="cover"
                            />
                        )}
                        <ClipSvg />
                    </ImageWrapper>
                </ImageContainer>
            </StyledLink>
            <Info size={size}>
                <StyledLink
                    href={`/kip-bestellen/${item?.url_key}`}
                    noUnderline
                >
                    <Name
                        style={{ cursor: "pointer" }}
                        size={size}
                        onClick={() =>
                            pushToDataLayer
                                ? prodcutClickDataLayer(
                                      item,
                                      positionInList,
                                      list,
                                      category
                                  )
                                : null
                        }
                    >
                        {item.name}
                    </Name>
                </StyledLink>
                <PriceButton>
                    <Price>
                        <span>{finalPrice}</span>
                        {` / kg`}
                    </Price>
                    <ButtonContainer>
                        <StyledButton
                            aria-label={`Voeg ${item.name} toe`}
                            disabled={!canAddProduct}
                            onClick={() => {
                                selectItemInDataLayer();
                                addProduct({
                                    category: item.categories?.[0]?.url_key,
                                    id: item.uid,
                                    name: item.name,
                                    price: item?.price_range?.maximum_price
                                        ?.final_price.value,
                                    quantity: 1,
                                    sku: item.sku,
                                    type: item.__typename
                                });
                            }}
                        >
                            <Icon icon={PLUS} size={32} />
                        </StyledButton>
                    </ButtonContainer>
                </PriceButton>
                {isSmallCard && !isMobileView && (
                    <MoreInfoContainer>
                        <Button
                            buttonType="link"
                            variant="blue"
                            size="link"
                            $showUnderlineOnMobile
                            href={`/kip-bestellen/${item?.url_key}`}
                            reversedOrder
                            icon={{
                                icon: ARROW_RIGHT,
                                size: isSmallCard ? 20 : 24
                            }}
                            onClick={() =>
                                pushToDataLayer
                                    ? prodcutClickDataLayer(
                                          item,
                                          positionInList,
                                          list,
                                          category
                                      )
                                    : null
                            }
                        >
                            Bekijk
                        </Button>
                    </MoreInfoContainer>
                )}
            </Info>
        </Container>
    );
}
