import { ReactNode } from "react";

import { StyledOrderedList } from "./List.styled";

export interface Props {
    children?: ReactNode;
}

export function OrderedList({ children }: Props): JSX.Element {
    return <StyledOrderedList>{children}</StyledOrderedList>;
}
