import { SbBlokData } from "@storyblok/react";
import dynamic from "next/dynamic";

import { Container } from "components/recipesPage/recipesPage.styled";
import { Hero } from "components/homePage/hero/Hero";
import { Wrapper } from "components/recipesPage/recipesPage.styled";
import DynamicComponent from "components/shared/dynamicComponent/DynamicComponent";
import Products from "components/homePage/products/Products";
import RecipesHeader from "components/shared/recipesHeader/RecipesHeader";
import TrustPilotCarousel from "components/trustPilotWidgets/carousel/TrustPilotCarousel";

import { GenericStoryBlok } from "types/storyblok";

/* eslint-disable */
//@ts-ignore
const PackagesSection = dynamic(() =>
    //@ts-ignore
    import("components/homePage/packagesSection/PackagesSection").then(
        mod => mod.PackagesSection
    )
);

//@ts-ignore
const Usps = dynamic(() =>
    //@ts-ignore
    import("components/homePage/usps/Usps").then(mod => mod.Usps)
);

//@ts-ignore
const About = dynamic(() =>
    //@ts-ignore
    import("components/homePage/about/About").then(mod => mod.About)
);
/* eslint-enable */

export function HomePage({
    products,
    packages,
    sbData
}: {
    products?: Array<MagentoProduct>;
    packages?: Array<MagentoProduct>;
    sbData: GenericStoryBlok;
}): JSX.Element {
    const homePageHero = sbData?.body?.find(
        (block: SbBlokData) => block.component === "homePageHero"
    );
    const homePageUsps = sbData?.body?.find(
        (block: SbBlokData) => block.component === "usps"
    );

    return (
        <>
            {homePageHero && <Hero {...homePageHero} />}
            {homePageUsps && <Usps {...homePageUsps} />}
            <Products items={products} />
            <TrustPilotCarousel />
            <About />
            {/*eslint-disable-next-line */}
            {/* @ts-ignore */}
            <PackagesSection packages={packages} />
            {/*eslint-disable-next-line */}
            {/* @ts-ignore */}
            <Wrapper isHomepage>
                <RecipesHeader />
                <Container>
                    {!!sbData?.blocks &&
                        sbData?.blocks?.map((block: SbBlokData) => (
                            <DynamicComponent blok={block} key={block._uid} />
                        ))}
                </Container>
            </Wrapper>
            {!!sbData?.body &&
                sbData?.body?.map((block: SbBlokData) => (
                    <DynamicComponent blok={block} key={block._uid} />
                ))}
        </>
    );
}
