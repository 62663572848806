import styled, { css } from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;

    ${media.mdUp} {
        align-items: center;
    }
`;

export const TitleContainer = styled.div`
    position: relative;
`;

export const Title = styled.h1`
    color: var(--color-brown-50);
    font-family: var(--font-secondary);
    font-weight: 100;

    ${media.mdUp} {
        margin-bottom: 32px;
        text-align: center;
    }
`;

export const OrangeTitle = styled.h2<{ isProductDetail?: boolean }>`
    color: var(--color-orange-50);
    font-family: var(--font-tertiary);
    font-size: 32px;
    font-weight: 100;
    left: 164px;
    line-height: 32px;
    position: absolute;
    top: -16px;
    transform: rotate(3.5deg);

    /* stylelint-disable */
    ${({ isProductDetail }) =>
        isProductDetail &&
        css`
            font-size: 20px;
            font-weight: 300;
            left: 0;
            position: relative;
            transform: rotate(0);

            ${media.mdUp} {
                &&& {
                    font-size: 32px;
                    font-weight: 500;
                    left: unset;
                }
            }
        `} /* stylelint-enable */

    ${media.mdUp} {
        left: 60%;
    }
`;

export const BlueTitle = styled(OrangeTitle)`
    color: var(--color-blue-50);

    ${media.mdUp} {
        left: 90%;
    }
`;

export const Text = styled.p`
    color: var(--color-brown-50);
    margin: 24px 0;

    ${media.mdUp} {
        text-align: center;
    }
`;
