import { useMemo, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";

import { ARROW_RIGHT, Button } from "components";
import {
    CarouselWrapper,
    Container,
    List,
    ListItem,
    SecondaryTitle,
    SliderWrapper,
    Title,
    Top
} from "components/homePage/products/Products.styled";
import Product from "components/shared/productItem/ProductItem";
import SliderControls, {
    SliderControlTheme
} from "components/shared/sliderControls/SliderControls";

import { useMediaQueryContext } from "helpers/hooks";

const Carousel = dynamic(() => import("nuka-carousel"));

const WINGS = "vleugels";
const FILET = "kipfilet";

export default function Products({
    items
}: {
    items?: Array<MagentoProduct>;
}): JSX.Element {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const { isDesktopView, isMobileView } = useMediaQueryContext();

    const highlightItems = useMemo(
        () =>
            items
                ?.filter(item => !item.name?.toLowerCase().includes(WINGS))
                .slice(0, 3)
                .sort(product =>
                    product.name?.toLowerCase().includes(FILET) ? -1 : 0
                ),
        [items]
    );

    const carouselItems = useMemo(
        () =>
            items?.map((item, index) => (
                <Product
                    key={`Product-${item.name}`}
                    item={item}
                    pushToDataLayer
                    positionInList={index + 1}
                    list="vlugge-vliegers"
                    category="Losse producten"
                />
            )),
        [items]
    );

    return (
        <Container>
            <Top>
                <SecondaryTitle>populairder dan ooit</SecondaryTitle>
                <Title>Vlugge vliegers</Title>
                <Link href="/kip-bestellen" passHref prefetch={false}>
                    <Button
                        variant="blue"
                        size="link"
                        buttonType="link"
                        icon={{ icon: ARROW_RIGHT }}
                        reversedOrder
                        $showUnderlineOnMobile
                    >
                        Alle producten
                    </Button>
                </Link>
            </Top>
            {isDesktopView && (
                <List>
                    {highlightItems?.map((item, index) => (
                        <ListItem key={`Product-list-item-${item.name}`}>
                            <Product
                                item={item}
                                pushToDataLayer
                                positionInList={index + 1}
                                list="vlugge-vliegers"
                                category="Losse producten"
                            />
                        </ListItem>
                    ))}
                </List>
            )}
            {!isDesktopView && (
                <>
                    <CarouselWrapper>
                        <Carousel
                            slidesToShow={isMobileView ? 1.3 : 2}
                            swiping
                            withoutControls
                            wrapAround
                            slideIndex={currentSlide}
                            afterSlide={nextSlide => {
                                setCurrentSlide(nextSlide);
                            }}
                        >
                            {carouselItems}
                        </Carousel>
                    </CarouselWrapper>
                    <SliderWrapper>
                        <SliderControls
                            isAllowedToLoop
                            theme={SliderControlTheme.PRIMARY}
                            currentSlide={currentSlide}
                            totalSlides={carouselItems?.length ?? 0}
                            onChange={nextSlide => {
                                setCurrentSlide(nextSlide);
                            }}
                        />
                    </SliderWrapper>
                </>
            )}
        </Container>
    );
}
