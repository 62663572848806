import Link from "next/link";

import { ARROW_RIGHT } from "components/shared/icon";
import { Button } from "components/shared/button";

import {
    BlueTitle,
    Container,
    Text,
    Title,
    TitleContainer
} from "./RecipesHeader.styled";

interface RecipesHeaderProps {
    taglineColor?: "orange" | "blue";
    showButton?: boolean;
}

export default function RecipesHeader({
    showButton = true
}: RecipesHeaderProps): JSX.Element {
    return (
        <Container>
            <TitleContainer>
                <Title as="h2">Recepten</Title>
                <BlueTitle>Kiplekker</BlueTitle>
            </TitleContainer>
            <Text>
                Dat is het hele eiereneten. <br /> Of kip in dit geval! De
                lekkerste gerechten, eenvoudig te bereiden met onze kakelverse
                kip.
            </Text>
            {showButton && (
                <Link href="/recepten" passHref>
                    <Button
                        variant="blue"
                        buttonType="link"
                        icon={{ icon: ARROW_RIGHT }}
                        reversedOrder
                        $showUnderlineOnMobile
                    >
                        Alle recepten
                    </Button>
                </Link>
            )}
        </Container>
    );
}
