import styled, { css } from "styled-components";

import { media } from "constants/media";

export const ImageContainer = styled.div`
    height: 160px;
    margin-bottom: 64px;
    position: relative;
    text-align: center;
    width: 100%;

    ::after {
        background: url("/images/pattern-green.svg");
        background-size: cover;
        bottom: -50px;
        content: "";
        height: 200px;
        left: -64px;
        position: absolute;
        width: 300%;
        z-index: -1;
    }

    ${media.mdUp} {
        height: 300px;
        margin-bottom: 212px;

        ::after {
            bottom: -175px;
            height: 372px;
            left: 0;
            width: 105%;
        }
    }
`;

export const Wrapper = styled.div<{ isHomepage?: boolean }>`
    margin: 0 auto;
    max-width: 1200px;
    padding: 24px;

    /* stylelint-disable-next-line */
    ${({ isHomepage }) =>
        isHomepage &&
        css`
            margin: 64px auto 0;

            ${media.mdUp} {
                margin: 128px auto 0;
            }

            a > div {
                margin: -16px 0 32px;
                padding: 16px 0;

                ${media.mdUp} {
                    display: flex;
                    justify-content: center;
                    margin: -32px auto 64px;
                    width: fit-content;
                }
            }
        `}
`;

export const Container = styled.section`
    margin-top: 64px;

    ${media.mdUp} {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }
`;
