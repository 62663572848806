import styled from "styled-components";

export const StyledUnorderedList = styled.ul`
    list-style: none;
    margin: 24px 0;

    li {
        align-items: center;
        display: flex;
        margin-bottom: 8px;

        p {
            margin-bottom: 0;
        }
    }
`;

export const StyledOrderedList = styled.ol`
    counter-reset: my-awesome-counter;
    list-style: none;

    li {
        align-items: flex-start;
        counter-increment: my-awesome-counter;
        display: flex;
        margin-bottom: 16px;

        ::before {
            content: "0" counter(my-awesome-counter);
            font-family: var(--font-tertiary);
            font-size: 24px;
            font-weight: 400;
            margin-top: 8px;
            min-width: 32px;
            padding-right: 16px;
        }

        p {
            align-items: center;
            display: flex;
            margin-bottom: 32px;
            margin-left: 16px;
        }
    }
`;
