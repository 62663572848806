import { EVENTS } from "../index";

export function prodcutClickDataLayer(
    product: MagentoProduct,
    positionInList: number,
    list: string,
    category: string
): void {
    window.dataLayer = window?.dataLayer || [];

    window?.dataLayer?.push({
        ecommerce: {
            actionField: {
                list
            },
            products: [
                {
                    brand: "Mr. Fillet",
                    category,
                    id: product.sku,
                    name: product.name,
                    position: positionInList,
                    price: product.price_per_kilogram,
                    variant: `${product.weight}kg`
                }
            ]
        },
        event: EVENTS.PRODUCT_CLICK
    });
}
