import { getStoryblokApi, useStoryblokState } from "@storyblok/react";
import { useEffect } from "react";
import Head from "next/head";
import type { GetStaticProps } from "next";

import { HomePage } from "components/homePage/HomePage";
import Layout from "components/layout/Layout";

import {
    GetBundledProducts,
    GetSimpleProducts
} from "helpers/transform/product";
import { GetProducts } from "helpers/api/magento";
import { initializeApollo } from "lib/apolloClient";
import { pageView } from "lib/gtag";

import { HomepageStory } from "types/storyblok";

function Home({
    products,
    story: initialStory
}: {
    story: HomepageStory;
    products: MagentoProducts;
}): JSX.Element {
    const story = useStoryblokState(initialStory);
    const simpleProducts = GetSimpleProducts(products?.items);
    const bundledProducts = GetBundledProducts(products?.items);
    const seo = story?.content?.seo || {};
    const seoTitle = seo?.title ?? "Mr. Fillet | Verse kip | Gratis bezorgd";
    const seoDescription =
        seo?.description ??
        "Verse kip online te bestellen bij Mr. Fillet. Gratis thuisbezorgd in heel Nederland. Op werkdagen voor 10:00u besteld, dezelfde dag geleverd. Bestel vandaag nog je kipfilet, kipdijfilet, kippenvleugels of kipdrumsticks!";

    useEffect(() => {
        if (simpleProducts && bundledProducts && window) {
            const ecommerceObj = {
                currencyCode: "EUR",
                impressions: [...simpleProducts, ...bundledProducts].map(
                    (item: MagentoProduct, index: number) => ({
                        brand: "Mr. Fillet",
                        category:
                            item.__typename === "SimpleProduct"
                                ? "Losse producten"
                                : "Pakketten",
                        id: item.sku,
                        list: "kip-bestellen",
                        name: item.name,
                        position: index + 1,
                        price: item.price_range?.maximum_price.final_price.value
                    })
                )
            };
            pageView("/", ecommerceObj);
        }
    }, [bundledProducts, simpleProducts]);

    return (
        <>
            <Head>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Layout showTagline taglineIsSpinning>
                <HomePage
                    sbData={story.content}
                    products={simpleProducts}
                    packages={bundledProducts}
                />
            </Layout>
        </>
    );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const apolloClient = initializeApollo();
    const storyblokApi = getStoryblokApi();

    const {
        data: { products }
    } = await apolloClient.query({
        context: {
            headers: { store: locale }
        },
        query: GetProducts,
        variables: { filter: {} }
    });
    const slug = "homepage";

    const sbParams = {
        resolve_relations: "category",
        version: process.env.NODE_ENV === "production" ? "published" : "draft"
    };
    const { data: page } = await storyblokApi.get(
        `cdn/stories/${slug}?cv=${Date.now()}`,
        sbParams
    );

    return {
        props: {
            initialApolloState: apolloClient.cache.extract(),
            products: products,
            story: page ? page.story : false
        },
        revalidate: 300
    };
};

export default Home;
