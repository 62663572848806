import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { SIZE } from "./ProductItem";

import { media } from "constants/media";

import type { AnimationProps } from "framer-motion";

interface ExtentedAnimationProps extends AnimationProps {
    size?: SIZE.SMALL | SIZE.LARGE;
}

export const ButtonContainer = styled.div`
    color: var(--color-white);
    z-index: 2;

    ${media.xlUp} {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }
`;

export const MoreInfoContainer = styled.div`
    margin-top: 16px;

    ${media.xlUp} {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
    background-color: var(--color-red-50);
    border: 0;
    border-radius: 50%;
    color: var(--color-beige-40);
    cursor: pointer;
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: var(--font-weight-normal);
    line-height: 32px;
    max-height: 64px;
    max-width: 64px;
    min-height: unset;
    padding: 16px;

    :active {
        background-color: var(--color-red-40);
        outline: 8px solid var(--color-red-40-transparent);
    }

    :hover {
        background-color: var(--color-red-40);
    }

    ${media.mdUp} {
        padding: 16px;
    }

    /* stylelint-disable-next-line */
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const Container = styled(motion.div)<ExtentedAnimationProps>`
    ${({ size }) =>
        size === SIZE.SMALL &&
        css`
            align-items: center;
            display: flex;
            flex-direction: "row"
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto 24px;
            max-width: 375px;
            width: 100%;

            ${ButtonContainer} {
                left: 20px;
                position: absolute;
                right: 20px;
                top: 100px;
            }

            ${media.mdUp} {
                flex-direction: column;
                margin: 0;
                max-width: 264px;
                padding: 0;

                ${ButtonContainer} {
                    display: block;
                    left: auto;
                    position: absolute;
                    right: -5px;
                    top: 205px;
                }
            }
        `};

    ${({ size }) =>
        size === SIZE.LARGE &&
        css`
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            ${media.mdUp} {
                ${ButtonContainer} {
                    position: absolute;
                    right: 20%;
                    top: 310px;
                }
            }
        `};

    position: relative;

    ${media.xlUp} {
        :hover {
            ${ButtonContainer} {
                opacity: 1;
            }

            ${MoreInfoContainer} {
                opacity: 1;
            }
        }
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
`;

export const ImageContainer = styled(motion.div)<ExtentedAnimationProps>`
    flex: 1;

    ${({ size }) =>
        size === SIZE.SMALL &&
        css`
            margin-left: 24px;
            margin-right: 24px;

            ${media.mdUp} {
                margin: 0;
            }
        `};

    position: relative;
`;

export const ImageWrapper = styled(motion.div)<{
    size: string;
}>`
    margin: 0 auto;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ size }) =>
        size === SIZE.SMALL &&
        css`
            height: 128px;
            width: 128px;

            ${media.mdUp} {
                height: 264px;
                width: 264px;
            }
        `};

    ${({ size }) =>
        size === SIZE.LARGE &&
        css`
            height: 200px;
            width: 202px;

            ${media.mdUp} {
                height: 372px;
                width: 368px;
            }
        `};

    /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const Name = styled.div<{ size: SIZE.SMALL | SIZE.LARGE }>`
    color: var(--color-brown-50);
    font-size: ${({ size }) => (size === SIZE.SMALL ? "16px" : "24px")};
    font-weight: 700;
    line-height: 32px;
    margin-bottom: ${({ size }) => (size === SIZE.SMALL ? "8px" : "20px")};
    overflow: hidden;
    text-align: center;
    width: 100%;

    ${media.mdUp} {
        color: var(--color-brown-50);
        font-size: 32px;
        line-height: 48px;
        margin-top: 32px;
    }
`;
export const Info = styled(motion.div)<ExtentedAnimationProps>`
    align-items: ${({ size }) =>
        size === SIZE.SMALL ? "flex-start" : "center"};
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    overflow: hidden;
    width: 100%;

    ${media.mdUp} {
        align-items: center;
        font-size: 18px;
        line-height: 32px;
    }
`;

export const PriceButton = styled.div`
    align-items: center;
    display: flex;
    gap: 52px;
`;

export const Price = styled.div`
    span {
        color: var(--color-orange-50);
        font-size: 16px;
        line-height: 24px;

        ${media.mdUp} {
            font-size: 18px;
            line-height: 32px;
        }
    }
`;

export const Anchor = styled.a`
    position: relative;
`;
